import React from 'react';
import './Services.css';

function Services() {
  const services = [
    {
      title: "Vigilancia 24/7",
      description: "Proporcionamos vigilancia continua para asegurar la protección de tus instalaciones en todo momento.",
      imgUrl: "https://via.placeholder.com/150"
    },
    {
      title: "Sistemas de alarmas",
      description: "Nuestros sistemas de alarmas de última generación garantizan una respuesta inmediata ante cualquier incidencia.",
      imgUrl: "https://via.placeholder.com/150"
    },
    {
      title: "Control de acceso",
      description: "Implementamos sistemas de control de acceso que permiten gestionar y restringir el acceso a tus instalaciones de manera eficiente.",
      imgUrl: "https://via.placeholder.com/150"
    },
    {
      title: "Consultoría de seguridad",
      description: "Ofrecemos asesoramiento experto para identificar y mitigar riesgos potenciales en tus instalaciones.",
      imgUrl: "https://via.placeholder.com/150"
    }
  ];

  return (
    <section className="services">
      <h2>Nuestros Servicios</h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <div key={index} className="service-item">
            <img src={service.imgUrl} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Services;
