import React from 'react';
import './Banner.css';

function Banner() {
  return (
    <section className="banner">
      <div className="banner-content">
        <h1 className="banner-title">Bienvenidos a Barna Segur</h1>
        <p className="banner-subtitle">Tu seguridad, nuestra prioridad</p>
      </div>
    </section>
  );
}

export default Banner;
