import React from 'react';
import Banner from '../components/Banner';
import Services from '../components/Services';
import ProductInfo from '../components/ProductInfo';
import Philosophy from '../components/Philosophy';
import BlogTopics from '../components/BlogTopics';
import './HomePage.css';

function HomePage() {
  return (
    <div>
      <Banner />
      <Services />
      <section className="service-details">
        <div className="container">
          <h2>SERVICIOS</h2>
          <p><strong>Instalación de sistemas de seguridad para tu hogar/pequeño negocio;</strong> gestionamos la vigilancia desde nuestra central en remoto para asegurar las 24h posibles imprevistos aportando seguridad a tu vivienda o negocio, <strong>protección y tranquilidad para ti y los tuyos.</strong></p>
          <p><strong>Instalación de sistema de vídeo vigilancia;</strong> visualiza desde cualquier lugar lo que está ocurriendo en tu hogar.</p>
          <p><strong>Servicios de vigilancia;</strong> presencial y móvil adaptadas a las necesidades de tu negocio.</p>
          <p><strong>Control de accesos;</strong> soluciones de seguridad enfocadas a preservar la integridad física y la salud del personal de tu empresa u organización.</p>
          <p><strong>Sistemas CCTV;</strong> soluciones de seguridad enfocadas a preservar la integridad física y la salud del personal de tu empresa u organización.</p>
          <p><strong>AntiPirata;</strong> seguridad adaptada a ti. Estudiamos soluciones personalizadas con la última tecnología, ofrecemos el mejor servicio adaptado a la realidad del momento, respaldado por nuestra experiencia siempre buscando ofrecer el mejor servicio y el producto más acorde a sus necesidades. Sabemos realmente cómo proteger tu hogar y tu negocio, no como los líderes en el mercado de empresas de seguridad que solo se preocupan de que pagues la cuota, nosotros te ofrecemos un servicio único para cada caso. Confía en nuestro equipo, ya verás como se nota realmente la diferencia de servicio. Podemos demostrar que nuestros operarios están mejor capacitados que los de la competencia.</p>
          <p>La experiencia y el conocimiento adquirido en el sector nos permite crear estrategias infalibles en prevención, vigilancia y actuación.</p>
          <p>Un exhaustivo propósito de mejora continuada nos ha llevado a considerar nuestros servicios de gran calidad.</p>
          <p>Solucionamos todo tipo de problemática con la seguridad en espacios cerrados especializadas y adaptadas al desarrollo de la actividad de nuestros clientes.</p>
        </div>
      </section>
      <ProductInfo />
      <Philosophy />
      <BlogTopics />
    </div>
  );
}

export default HomePage;
