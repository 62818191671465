import React from 'react';
import './BlogTopics.css';

function BlogTopics() {
  return (
    <section className="blog-topics">
      <div className="container">
        <h2>TEMAS BLOG</h2>
        <ol>
          <li>Inseguridad en Barcelona</li>
          <li>Okupación o ocupación</li>
          <li>Buena praxis en instalaciones</li>
        </ol>
      </div>
    </section>
  );
}

export default BlogTopics;
