import React from 'react';
import './Philosophy.css';

function Philosophy() {
  return (
    <section className="philosophy">
      <div className="container">
        <h2>Nuestra filosofía</h2>
        <p>Ayudar a las personas a prevenir robos y okupaciones en la provincia de Barcelona.</p>
        <p>Con la experiencia y la personalización de nuestros servicios.</p>
      </div>
    </section>
  );
}

export default Philosophy;
