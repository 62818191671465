import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ProductInfo.css';

function ProductInfo() {
  const products = [
    {
      title: "Alarma de Seguridad XYZ",
      description: "La última tecnología en sistemas de alarma para tu hogar y negocio. Ofrece monitorización en tiempo real y respuesta inmediata.",
      imgUrl: "https://via.placeholder.com/300"
    },
    {
      title: "Cámara de Seguridad ABC",
      description: "Cámaras de alta resolución con visión nocturna y almacenamiento en la nube para garantizar la máxima seguridad.",
      imgUrl: "https://via.placeholder.com/300"
    },
    {
      title: "Sistema de Control de Acceso",
      description: "Gestione y controle el acceso a sus instalaciones con nuestro avanzado sistema de control de acceso.",
      imgUrl: "https://via.placeholder.com/300"
    }
  ];

  return (
    <section className="product-info">
      <h2>Producto Destacado</h2>
      <Carousel
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        useKeyboardArrows
        autoPlay
        interval={5000}
        transitionTime={1000}
        showIndicators={false}
      >
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.imgUrl} alt={product.title} />
            <div className="product-details">
              <h3>{product.title}</h3>
              <p>{product.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </section>
  );
}

export default ProductInfo;
